@import "../../scss/main.scss";

.wrapper {
  border-bottom: 1px solid $shadow;
  display: flex;
  // align-items: center;
  transition: all 0.2s linear;
  cursor: pointer;
  position: relative;
  &:hover {
    background: rgb(250, 250, 250);
  }
  display: grid;
  grid-template-columns:
    minmax(200px, 1fr) 180px minmax(50px, 180px)
    minmax(100px, 180px) 30px;
  min-height: 55px;

  & > div {
    overflow-wrap: break-word;
    display: flex;
    align-items: center;
    @include text_staff_regular;

    &.name {
      @include text_staff_bold;
    }
  }

  .select {
    position: relative;
    align-self: center;

    .selectBtn {
      display: flex;
      align-items: center;
      justify-content: center;
      cursor: pointer;
      z-index: 2;
      &:hover {
        opacity: 0.9;
      }
    }
  }
}
