@import "../../scss/main.scss";

.overlay {
  // display: flex;
  // justify-content: center;
  // position: fixed;
  // top: 0;
  // bottom: 0;
  // left: 0;
  // right: 0;
  // background: rgba(0, 0, 0, 0.58);
  position: fixed;
  display: flex;
  justify-content: center;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.58);
  z-index: 1000;
  overflow-y: auto;
}

.modal {
  & .modalWrapper {
    display: flex;
    justify-content: center;
    overflow: auto;
    margin: 50px 0;
  }
  & .innerContainer {
    position: relative;
    width: 460px;
    margin-top: 50px;
    padding: 32px;
    border-radius: 24px;
    background: $white;
  }
  .header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 22px;
    h5 {
      margin: 0;
      font-family: $primary-font;
      font-size: 18px;
      line-height: 24px;
      font-weight: 700;
      font-size: 18px;
      line-height: 24px;
    }
    svg {
      cursor: pointer;
    }
  }
  .title {
    @include text_staff_subtitle;
  }
}
.btnContainer {
  margin-top: 15px;
  display: flex;
  justify-content: space-between;
  div {
    button {
      background: $grayE4 !important;
      div {
        color: $black15 !important;
      }
    }
  }
}
.questionBody {
  h2 {
    @include h3;
    text-align: center;
  }
}
