@import "../../../scss/main.scss";

.wrapper {
  display: flex;
  flex-direction: column;
  gap: 25px;

  & .loaderWrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 70vh;
  }

  .info {
    display: flex;
    align-items: center;
    gap: 20px;

    & .avatar {
      min-width: 100px;
      width: 100px;
      height: 100px;
      border-radius: 100px;
      object-fit: cover;
      overflow: hidden;
    }

    & .nameWrapper {
      & .name {
        @include h1;
        margin-bottom: 7px;
      }

      & .title {
        font-family: $secondary-font;
        font-size: 14px;
        line-height: 20px;
        color: $gray60;
        font-weight: 400;
      }
    }
  }

  & .about {
    @include text_statistic_regular;
  }

  & .tabMenu {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  & .addNewBtn {
    width: auto;
    padding: 0 25px;
  }

  & .tabWrapper {
    display: flex;
    gap: 2px;
  }
}

.btnWrapper {
  display: flex;
  gap: 10px;
}

.groupsWrapper {
  & .sectionHeader {
    // display: flex;
    position: relative;
    display: grid;
    overflow-wrap: normal;
    grid-template-columns:
      minmax(200px, 1fr) 180px minmax(50px, 180px)
      minmax(100px, 180px) 30px;
    align-items: center;
    padding-bottom: 7px;
    border-bottom: 1px solid $shadow;

    & > div {
      display: flex;
      align-items: center;
      @include text_x_small_extrabold;
      transition: 0.3s;
      color: $grayBD;
      gap: 3px;

      & svg {
        stroke: $grayBD;
        cursor: pointer;
      }
      p {
        cursor: pointer;
      }
    }
  }
  & .groupsContainer {
    & .loaderWrapper {
      display: flex;
      align-items: center;
      justify-content: center;
      height: 50vh;
    }
  }
}
