@import "../../scss/main.scss";

.checkbox {
  width: 20px;
  height: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 4px;
  border: 2px solid $grayE4;
  cursor: pointer;

  &Main {
  }

  &Checked {
    border-color: $primary;
    background-color: $primary;
  }
}
