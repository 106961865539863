@import "../../scss/main.scss";

.businessBody {
  position: relative;
  & .select {
    .arrowBtn {
      cursor: pointer;
      width: 24px;
      height: 24px;
      background: $primary;
      border-radius: 100px;
      display: flex;
      align-items: center;
      justify-content: center;
      z-index: 2;
      & svg {
        transform: rotate(-90deg);
      }
      &:hover {
        opacity: 0.9;
      }
    }
  }
}
.wrapper {
  border-bottom: 1px solid $shadow;
  display: flex;
  align-items: center;
  transition: all 0.2s linear;
  cursor: pointer;
  position: relative;
  &:hover {
    background: rgb(250, 250, 250);
  }
  display: grid;
  grid-template-columns:
    minmax(220px, 1fr) minmax(50px, 110px) minmax(120px, 400px)
    minmax(80px, 110px) minmax(100px, 200px) 30px;
  min-height: 86px;

  .eventBody {
    display: flex;
    align-items: center;
    gap: 15px;
  }

  .eventImage {
    width: 84px;
    height: 56px;
    object-fit: cover;
    border-radius: 10px;
    overflow: hidden;
    min-width: 84px;
  }

  .eventInfo {
    display: flex;
    flex-direction: column;
    gap: 2px;
  }

  .title {
    @include text_staff_bold;
    color: $black15;
  }
  .name {
    @include text_staff_regular;
    color: $gray91;
  }

  .price,
  .location,
  .members,
  .date {
    p {
      overflow-wrap: break-word;

      @include text_staff_regular;
      color: $black15;
    }
  }
}
