@import "../../scss/main.scss";

.businessBody {
  position: relative;
}
.wrapper {
  border-bottom: 1px solid $shadow;
  display: flex;
  align-items: center;
  transition: all 0.2s linear;
  cursor: pointer;
  position: relative;
  &:hover {
    background: rgb(250, 250, 250);
  }
  display: grid;
  grid-template-columns:
    24px minmax(172px, 330px) minmax(120px, 280px) minmax(90px, auto)
    80px;

  & .ownerLocationWrapper {
    & p {
      overflow-wrap: break-word;

      @include text_staff_regular;
      color: $black15;
    }
  }
  & .businessLocationWrapper {
    padding: 10px 0;
    & p {
      overflow-wrap: break-word;

      @include text_staff_regular;
      color: $black15;
    }
  }
  & .image {
    min-width: 80px;
    width: 80px;
    height: 80px;
    border-radius: 10px;
    overflow: hidden;
    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }
}
.acceptButtons {
  display: flex;
  gap: 5px;
}
.staffName {
  gap: 15px;
  margin-left: 24px;
  overflow-wrap: break-word;
  height: 100%;
  margin: 0 0 0 16px;
  display: flex;
  align-items: center;

  @include text_staff_bold;
}
.ownerLocationBody {
  height: 100%;
  display: flex;
  align-items: center;
}
