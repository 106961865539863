@import "../../scss/main.scss";

.overlay {
  position: fixed;
  display: flex;
  justify-content: center;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.58);
  z-index: 1000;
  overflow-y: auto;
}

.modal {
  & .modalWrapper {
    display: flex;
    justify-content: center;
    overflow: auto;
    margin: 50px 0;
  }
  & .innerContainer {
    position: relative;
    width: 512px;
    height: 828px;
    margin-top: 50px;
    border-radius: 20px;
    overflow: hidden;
    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
    .closeBtn {
      position: absolute;
      cursor: pointer;
      width: 32px;
      height: 32px;
      display: flex;
      justify-content: center;
      align-items: center;
      background: rgba(17, 20, 45, 0.4);
      border-radius: 30px;
      top: 20px;
      right: 20px;
      z-index: 20;
    }
  }
}
