@import "../../../../scss/main.scss";
.photosWrapper {
  display: flex;
  flex-wrap: wrap;
  gap: 5px;
}
.photosBody {
  margin-bottom: 5px;
  min-width: 123px;
  width: 123px;
  height: 123px;
  border-radius: 10px;
  overflow: hidden;
  position: relative;
  cursor: pointer;
  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
  .trash {
    position: absolute;
    top: 3px;
    right: 3px;
    width: 28px;
    height: 28px;
    display: flex;
    justify-content: center;
    align-items: center;
    background: rgba(0, 0, 0, 0.6);
    cursor: pointer;
    border-radius: 90px;
  }
}
.photosEmpty {
  @include text_medium_bold;
}
