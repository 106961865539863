@import "../../scss/main.scss";

.overlay {
  position: fixed;
  display: flex;
  justify-content: center;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.58);
  z-index: 1000;
  overflow-y: auto;
}

.modal {
  & .innerContainer {
    position: relative;
    width: 460px;
    margin-top: 100px;
    padding: 32px;
    border-radius: 24px;
    background: $white;
  }
  .header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 22px;
    h5 {
      margin: 0;
      font-family: $primary-font;
      font-size: 18px;
      line-height: 24px;
      font-weight: 700;
      font-size: 18px;
      line-height: 24px;
    }
    svg {
      cursor: pointer;
    }
  }
}
.category {
}
.title {
}
.description {
}
.form {
  display: flex;
  flex-direction: column;
  row-gap: 22px;

  button {
    border-radius: 16px;
  }
}
.commonError {
  font-size: 11px;
  color: $red;
  line-height: 18px;
  margin: 10px 0 0 0;
  margin-bottom: 10px;
}
.input {
  flex: 1;

  span {
    @include text_form_label;
    color: $grayBD;
  }

  textarea {
    @include text_input_bold;
    caret-color: $primary;
    color: $black;
  }
}
