@import "../../../../scss/main.scss";
.videosWrapper {
  display: flex;
  flex-wrap: wrap;
  gap: 5px;
}
.videosBody {
  margin-bottom: 20px;
  width: 234px;
  height: 309px;
  border-radius: 10px;
  overflow: hidden;
  position: relative;
  cursor: pointer;
  img,
  video {
    width: 100%;
    height: 100%;
    object-fit: cover;
    pointer-events: none;
  }
  .triangle {
    position: absolute;
    width: 0;
    height: 0;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    border-style: solid;
    border-width: 16px 0 16px 28px;
    border-color: transparent transparent transparent #ffffff;
  }
  .trash {
    position: absolute;
    top: 3px;
    right: 3px;
    width: 28px;
    height: 28px;
    display: flex;
    justify-content: center;
    align-items: center;
    background: rgba(0, 0, 0, 0.6);
    cursor: pointer;
    border-radius: 90px;
  }
  .videoInfo {
    position: absolute;
    width: 100%;
    bottom: 0;
    background: linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, #000000 100%);
    p {
      margin-bottom: 20px;
      @include text_staff_bold;
      color: $white;
      padding-left: 15px;
      max-height: 60px;
      overflow-y: hidden;
    }
  }
  .videoTag {
    position: absolute;
    top: 6px;
    left: 6px;
    background-color: $primary;
    padding: 4px 8px;
    border-radius: 8px;
    p {
      @include tag-primary;
      color: $white;
      overflow-y: hidden;
    }
  }
}
.videosEmpty {
  @include text_medium_bold;
  margin-bottom: 20px;
}
.videoPaid {
  width: 234px;
  height: 309px;
  border-radius: 10px;
  overflow: hidden;
  position: relative;
  cursor: pointer;
  border: 2px dashed $primary;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  row-gap: 10px;

  img,
  video {
    width: 100%;
    height: 100%;
    object-fit: cover;
    pointer-events: none;
  }

  .videoBtn {
    @include text-menu;
    color: $primary;
  }
}

.loader {
  position: absolute;
  width: 100%;
  height: 100%;
  background-color: #e1e4e7;
  // border-radius: 100px;
  z-index: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  top: 0;
  left: 0;
}
