@import "../../scss/main.scss";

.overlay {
  // display: flex;
  // justify-content: center;
  // position: absolute;
  // overflow: auto;
  // // height: 80vw;
  // top: 0;
  // bottom: 0;
  // left: 0;
  // right: 0;
  // background: rgba(0, 0, 0, 0.58);
  position: fixed;
  display: flex;
  justify-content: center;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.58);
  z-index: 1000;
  overflow-y: auto;
}

.modal {
  & .innerContainer {
    position: relative;
    width: 928px;
    margin-top: 100px;
    padding: 32px;
    border-radius: 24px;
    background: $white;
  }
  .header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 22px;
    h5 {
      @include text_statistic_bold;
      margin: 0;
      font-size: 18px;
      line-height: 24px;
      font-size: 24px;
      line-height: 32px;
    }
    svg {
      cursor: pointer;
    }
  }
}
.tagBody {
  display: flex;
  gap: 5px;
}
.mainInfo {
  margin-bottom: 20px;
  p {
    @include h1;
    margin: 0 0 5px 0;
  }
  .tagContainer {
    display: flex;
    justify-content: space-between;
    span {
      @include text_x_small_medium;
    }
  }
}
.content {
  max-width: 691px;
  p {
    @include text_staff_subtitle;
    color: $black15;
  }
}
.modalBody {
  display: flex;
  gap: 50px;
}
.userInfo {
  display: flex;
  flex-direction: column;
  gap: 15px;
}
.userProfile {
  display: flex;
  gap: 10px;
  .image {
    min-width: 44px;
    width: 44px;
    height: 44px;
    img {
      width: 100%;
    }
  }
  .userName {
    @include text_staff_bold;
  }
  .personalInfo {
    display: flex;
    flex-direction: column;
    span {
      display: block;
    }
    .userLocation {
      @include text_staff_subtitle;
    }
  }
}
.userSection {
  display: flex;
  flex-direction: column;
  span {
    display: block;
  }
  .title {
    @include text_staff_subtitle;
  }
  .subtitle {
    @include text_staff_bold;
  }
}
.dividerX {
  height: 1px;
  width: 100%;
  background: $grayE4;
}
.approveBody {
  flex: 1 1 auto;
}
.approveFlex {
  display: flex;
  gap: 22px;
  & .approveImage {
    width: 434px;
    height: 640px;
    border-radius: 5px;
    overflow: hidden;
    img {
      width: 100%;
      height: 100%;
      object-fit: contain;
    }
  }
}
.approveSection {
  background: rgba(228, 228, 228, 0.25);
  border-radius: 15px;
  padding: 25px 20px;
  width: 408px;
  margin-bottom: 20px;
  .radio {
    display: flex;
    flex-direction: column;
    span {
      font-size: 16px;
    }
  }
}
.subRadio {
  margin: 0 30px;
  p {
    margin: 0;
    @include text_staff_subtitle;
  }
  span {
    font-weight: 400;
    font-size: 13px;
  }
}
