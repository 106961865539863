@import "../../../../../scss/main.scss";

.followers {
  &__table {
    &--user {
      display: flex;
      align-items: center;

      &-avatar {
        flex-shrink: 0;
        min-width: 44px;
        width: 44px;
        height: 44px;
        border-radius: 50%;
        overflow: hidden;
        margin-right: 15px;

        & img {
          width: 100%;
          height: 100%;
          object-fit: cover;
        }
      }

      &-name {
        @include text_staff_bold;
      }
    }

    &--action {
      display: flex;
      align-items: center;
      justify-content: center;
      flex-shrink: 0;
      width: 24px;
      height: 24px;
      background: $primary;
      border-radius: 50%;
      cursor: pointer;

      svg {
        transform: rotate(-90deg);
      }
    }
  }
}
