@import "../../scss/main.scss";

.businessBody {
  position: relative;
  & .select {
    position: absolute;
    top: 33%;
    right: 0;
    .arrowBtn {
      cursor: pointer;
      width: 24px;
      height: 24px;
      background: $primary;
      border-radius: 100px;
      display: flex;
      align-items: center;
      justify-content: center;
      z-index: 2;
      & svg {
        transform: rotate(-90deg);
      }
      &:hover {
        opacity: 0.9;
      }
    }
  }
}
.wrapper {
  border-bottom: 1px solid $shadow;
  display: flex;
  align-items: center;
  transition: all 0.2s linear;
  cursor: pointer;
  position: relative;
  &:hover {
    background: rgb(250, 250, 250);
  }
  display: grid;
  // grid-template-columns: 24px 255px 139px 155px 128px 100px 100px;
  grid-template-columns: 
  //checkbox
  // 24px
    minmax(140px, 272px) minmax(50px, 206px) minmax(50px, 195px) minmax(
      100px,
      142px
    );
  & .avatarWrapper {
    flex: 1 0 auto;
    display: flex;
    align-items: center;
    gap: 15px;
    margin-left: 24px;
    overflow-wrap: break-word;

    & .avatar {
      min-width: 44px;
      width: 44px;
      height: 44px;
      border-radius: 100px;
    }

    & .name {
      @include text_staff_bold;
      span {
        display: block;
        @include text_staff_subtitle;
      }
    }
  }

  & .ownerLocationWrapper {
    flex: 0.6 0 auto;
    & p {
      overflow-wrap: break-word;

      @include text_staff_regular;
      color: $black15;
    }
  }
  & .businessLocationWrapper {
    flex: 1 0 auto;

    & p {
      overflow-wrap: break-word;

      @include text_staff_regular;
      color: $black15;
    }
  }
  & .businessTypeWrapper {
    flex: 1.6 1 auto;

    & p {
      overflow-wrap: break-word;

      @include text_staff_regular;
      color: $black15;
    }
  }
  & .revenueWrapper {
    flex: 0.5 0 auto;

    & p {
      overflow-wrap: break-word;

      @include text_staff_regular;
      color: $black15;
    }
  }
  & .employeesWrapper {
    flex: 1 1 auto;

    & p {
      overflow-wrap: break-word;

      @include text_staff_regular;
      color: $black15;
    }
  }
  // & .dateWrapper {
  //   flex: 0.8;
  //   display: flex;
  //   align-items: center;
  //   justify-content: space-between;

  //   & p {
  //     @include text_staff_regular;
  //   }

  //   & .arrowBtn {
  //     width: 24px;
  //     height: 24px;
  //     background: $primary;
  //     border-radius: 100px;
  //     display: flex;
  //     align-items: center;
  //     justify-content: center;
  //     & svg {
  //       transform: rotate(-90deg);
  //     }
  //   }
  // }
}
.ownerLocationBody {
  display: flex;
  align-items: center;
  height: 100%;
}
