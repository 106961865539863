@import "../../../scss/main.scss";

.wrapper {
  & .loaderWrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 70vh;
  }

  .info {
    display: flex;
    align-items: center;
    gap: 20px;

    & .avatar {
      min-width: 78px;
      width: 78px;
      height: 78px;
      border-radius: 100px;
      object-fit: cover;
      overflow: hidden;
    }

    & .nameWrapper {
      & .name {
        @include h1;
        margin: 0;
        span {
          @include text_staff_regular;
          color: $gray91;
          font-size: 24px;
          font-weight: 700;
          line-height: 32px;
        }
      }

      & .location {
        display: flex;
        align-items: center;
        gap: 6px;
        @include text_staff_subtitle;
        font-family: $secondary-font;
        line-height: 20px;
        color: $gray60;
        margin: 0;
        .locationImage {
          width: 19px;
          height: 24px;
          svg {
            width: 100%;
          }
        }
      }
    }
    .role {
      @include text_staff_subtitle;
      font-family: $secondary-font;
      line-height: 20px;
      color: $gray60;
      margin-top: 7px;
      margin-left: 2px;
    }

    & .followersStatsWrapper {
      display: flex;
      align-items: center;
      gap: 20px;
      margin-left: auto;
      margin-right: 50px;
      & .divider {
        width: 1px;
        height: 25px;
        background-color: $grayBD;
      }
      & .block {
        & .counter {
          @include h1;
        }

        & .subtitle {
          @include text_staff_regular;
          color: $grayBD;
        }
      }
    }
    & .socialbtnWrapper {
      display: flex;
      align-items: center;
      gap: 5px;

      & .socialBtn {
        cursor: pointer;
      }
    }
  }

  & .tabWrapper {
    display: flex;
    gap: 2px;
    margin-top: 25px;
    margin-bottom: 25px;
  }

  & .profileInfo {
    display: flex;
    justify-content: space-between;
    margin-top: 25px;
    padding-bottom: 20px;
    border-bottom: 2px solid $shadow;
    & .profileBlock {
      flex: 1;
      & .profleTitle {
        @include h3;
        margin-bottom: 10px;
      }
      & .date {
        @include text_small_bold;
        color: $primary;
      }
    }
  }
}

.btnWrapper {
  display: flex;
  gap: 10px;
}
