@import "../../scss/main.scss";

.businessBody {
  position: relative;
  & .select {
    position: absolute;
    top: 33%;
    right: 0;
    .arrowBtn {
      cursor: pointer;
      width: 24px;
      height: 24px;
      background: $primary;
      border-radius: 100px;
      display: flex;
      align-items: center;
      justify-content: center;
      z-index: 2;
      & svg {
        transform: rotate(-90deg);
      }
      &:hover {
        opacity: 0.9;
      }
    }
  }
}
.wrapper {
  border-bottom: 1px solid $shadow;
  display: flex;
  align-items: center;
  transition: all 0.2s linear;
  cursor: pointer;
  position: relative;
  &:hover {
    background: rgb(250, 250, 250);
  }
  display: grid;
  grid-template-columns:
    minmax(170px, 350px) minmax(130px, 180px) minmax(185px, 240px) minmax(
      185px,
      260px
    )
    minmax(185px, 115px)
    30px;

  & .type {
    & p {
      overflow-wrap: break-word;

      @include text_staff_regular;
      color: $black15;
      font-weight: 700;
      font-size: 14px;
      line-height: 20px;
    }
  }
  & .reportedOn {
    & p {
      overflow-wrap: break-word;

      @include text_staff_regular;
      color: $black15;
    }
  }
  & .date {
    & p {
      overflow-wrap: break-word;

      @include text_staff_regular;
      color: $black15;
    }
  }
}
.acceptButtons {
  display: flex;
  gap: 5px;
}
