@import "../../../../../scss/main.scss";

.meetings {
  &__table {
    &--description {
      text-overflow: ellipsis;
      white-space: nowrap;
      overflow: hidden;
      display: block;
    }

    &--link {
      text-overflow: ellipsis;
      white-space: nowrap;
      overflow: hidden;
      display: block;
      color: $primary;
    }
  }
}
