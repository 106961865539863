@import "../../../../scss/main.scss";

.input {
  width: 447px;
}
.userPosts {
  & .paginationContainer {
    margin-top: 30px;
    display: flex;
    gap: 8px;

    & .nextBtn {
      cursor: pointer;
      background-color: $primary;
      padding: 14px 30px;
      border-radius: 16px;
      font-weight: 700;
      font-size: 14px;
      line-height: 20px;
      color: $white;
      font-family: $secondary-font;
    }
  }
  & .loaderWrapper {
    display: flex;
    align-items: center;
    justify-content: center;
  }
}
.empty {
  @include text_medium_bold;
}
