@import "../../scss/main.scss";
.interestTagBody {
  background: #fff8f1;

  border: 2px solid #f4ece3;
  border-radius: 60px;
  padding: 6px 10px;
  display: flex;
  align-items: center;
  svg {
    flex: 1 0 auto;
  }
  gap: 5px;
  p {
    margin: 0;
    @include text_staff_regular;
    font-family: $secondary-font;
    max-width: 320px;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    color: $black1E;
  }
}
