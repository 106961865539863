@import "../../scss/main.scss";

.divider {
  flex: 0 0 auto;
  width: 2px;
  background: $grayE4;
  opacity: 0.4;
}
.layoutWrapper {
  display: flex;
  flex: 1 1 auto;
  position: relative;
}

.body {
  padding: 40px 50px 0;
  flex: 1 1 1000px;
  overflow-x: hidden;
}
.container {
  // max-width: 1440px;
  display: flex;
  flex-direction: column;
  margin: 0 auto;
  min-height: 100vh;
}
.title {
  @include h1;
  margin-bottom: 35px;
}

.contentWrapper {
  background: #ffffff;
  box-shadow: 0px 0 8px rgba(227, 230, 236, 1.4);
  border-radius: 15px;
  padding: 30px;
  margin-bottom: 50px;
}
.subtitle {
  display: inline-flex;
  gap: 10px;
  align-items: center;
  cursor: pointer;
  h3 {
    @include h3;
    letter-spacing: 0.1px;
  }
}
.subPage {
  display: flex;
  justify-content: space-between;
  align-items: baseline;
  margin: 15px 0 30px;
}
