@import "../../scss/main.scss";

.pagination {
  display: flex;
  align-items: center;
  gap: 5px;

  & a {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  &__page {
    width: 48px;
    height: 48px;
    border-radius: 16px;
    background-color: $grayE4;
    font-family: $secondary-font;
    font-weight: 700;
    font-size: 14px;
    color: $black2D;
    line-height: 20px;
    cursor: pointer;
    transition: 0.1s;

    &-active {
      background-color: $primary;
      color: $white;
    }
  }

  &__break {
    width: 48px;
    height: 48px;
    background-color: $white;
    border-radius: 16px;
    font-weight: 700;
    font-size: 14px;
    color: $black2D;
    cursor: pointer;
    transition: 0.1s;
  }

  &__button {
    height: 48px;
    padding: 0 24px;
    background-color: $primary;
    border-radius: 16px;
    font-weight: 700;
    font-size: 14px;
    color: $white;
    line-height: 20px;
    cursor: pointer;

    &-hide {
      display: none;
    }
  }
}
