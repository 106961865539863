@import "./../../scss/main.scss";

.react-calendar {
  width: 290px;
  padding: 10px 4px;
  background-color: $white;
  border-radius: 16px;
  border: none;
  box-shadow: 0px 3px 6px -4px rgba(0, 0, 0, 0.12),
    0px 6px 16px rgba(0, 0, 0, 0.08), 0px 9px 28px 8px rgba(0, 0, 0, 0.05);
  font-family: $primary-font;

  &__navigation {
    display: flex;
    align-items: center;
    padding: 0 10px;
    margin-bottom: 5px;

    &__prev2-button,
    &__next2-button {
      display: none !important;
    }

    &__arrow {
      max-width: 32px;
      min-width: 0;
      height: 32px;
      font-size: 30px;
      user-select: none;
      transition: 0.2s;
    }

    &__prev-button {
      padding: 0 2px 7px 0;
    }

    &__next-button {
      padding: 0 0 7px 2px;
    }

    &__label {
      pointer-events: none;

      &__labelText {
        font-weight: 600;
        font-size: 14px;
        color: $black;
      }
    }
  }

  &__month {
    &-view {
      &__weekdays {
        &__weekday abbr {
          font-weight: 600;
          font-size: 14px;
          color: $black;
          text-transform: capitalize;
          text-decoration: none !important;
        }
      }

      &__days__day {
        &--neighboringMonth {
          & > abbr {
            color: $gray91;
          }
        }
      }
    }
  }

  &__tile {
    padding: 0;
    color: $black2D;
    transition: 0.2s;

    &:disabled {
      background-color: transparent;
      color: $grayBD;
      cursor: not-allowed;
    }

    & > abbr {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 40px;
      height: 40px;
      border-radius: 50%;
    }

    &--active {
      background-color: $primary !important;
      color: $white;

      & > abbr {
        color: $white;
      }
    }

    &--now {
      background-color: transparent;

      &:enabled:hover {
        background-color: #e6e6e6;
      }
    }
  }
}
