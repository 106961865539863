@import "../../scss/main.scss";

.date-picker {
  position: relative;

  &__title {
    font-family: $secondary-font;
    font-weight: 500;
    font-size: 12px;
    color: $deactive;
    line-height: 16px;
    margin-bottom: 10px;
  }

  &__value {
    display: flex;
    align-items: center;
    gap: 10px;
    background: rgba(228, 228, 228, 0.35);
    padding: 14px 21px;
    border: 2px solid transparent;
    border-radius: 8px;
    user-select: none;
    cursor: pointer;
    transition: 0.2s;

    &--text {
      flex: 1;
      font-weight: 600;
      font-size: 14px;
      color: $black2D;
      line-height: 22px;

      &-placeholder {
        font-weight: 400;
        color: $deactive;
      }
    }

    &--arrow {
      width: 18px;
      transition: 0.2s;

      &-active {
        transform: rotate(-180deg);
      }
    }

    &-error {
      border-color: $red;
    }
  }

  &__calendar {
    position: absolute;
    bottom: 0;
    left: 0;
    transform: translateY(100%);
    z-index: 1;
  }

  &__error {
    font-size: 11px;
    color: $red;
    line-height: 18px;
    margin-top: 3px;
  }
}
