@import "../../scss/main.scss";

.wrapper {
  display: flex;
  align-items: center;
  background-color: #ffffff;
  flex-direction: column;
  gap: 50px;
  height: 100vh;
  padding-top: 50px;

  & .signInForm {
    width: 460px;
    background: #ffffff;
    box-shadow: 0px 15px 80px rgba(227, 230, 236, 0.8);
    border-radius: 24px;
    padding: 30px;
    display: flex;
    flex-direction: column;
    row-gap: 20px;

    & .checkboxContainer {
      display: flex;
      flex-direction: row;
      align-items: center;
      gap: 8px;
      & span {
        @include text_x_small_regular;
      }
    }

    & .error {
      @include text_x_small_regular;
      text-align: center;
      color: $red;
    }
  }
}
