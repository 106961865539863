@import "../../../scss/main.scss";
.input {
  width: 450px;
}
.navBody {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 28px;
}
.filter {
  width: 48px;
  height: 48px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: $white;
  box-shadow: 0px 0 8px rgba(227, 230, 236, 1.4);
  cursor: pointer;
  transition: 0.3s;
  position: relative;
  &:hover {
    background: $grayE4;
  }
}
.filterBody {
  position: relative;
}
.filterDetails {
  opacity: 0;
  visibility: hidden;
  position: absolute;
  z-index: 10;
  top: 60px;
  right: 0;
}
.filterDetailsOpen {
  opacity: 1;
  visibility: visible;
  position: absolute;
  z-index: 10;
  top: 60px;
  right: 0;
}
.filterSelected {
  width: 48px;
  height: 48px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: $primary;
  box-shadow: 0px 0 8px rgba(227, 230, 236, 1.4);
  cursor: pointer;
  transition: 0.3s;
  position: relative;

  &:hover {
    opacity: 0.9;
  }
}
.svgOpen {
  filter: brightness(0) invert(1);
}

.wrapper {
  max-width: 1520px;
  & .header {
    display: flex;
    justify-content: space-between;
    align-items: center;

    & .title {
      @include text_statistic_bold;
    }
  }
  & .contentContainer {
    & .searchContainer {
      display: flex;
      align-items: center;
      margin: 28px 0;
    }

    & .sectionHeader {
      // display: flex;
      position: relative;
      display: grid;
      overflow-wrap: normal;
      grid-template-columns: minmax(170px, 350px) minmax(130px, 180px) minmax(185px, 240px) minmax(185px, 260px) minmax(
          185px,
          115px
        ) 30px;
      align-items: center;
      padding-bottom: 7px;
      border-bottom: 1px solid $shadow;
      & .nameBlock {
        flex: 5 1 auto;
        display: flex;
        align-items: center;
        @include text_x_small_extrabold;
        color: $grayBD;
        margin-left: 24px;
        gap: 3px;

        & svg {
          stroke: $grayBD;
          cursor: pointer;
        }
        p {
          cursor: pointer;
        }
      }
      & .industryBlock {
        flex: 1 1 auto;

        display: flex;
        align-items: center;
        @include text_x_small_extrabold;
        color: $grayBD;
        gap: 3px;

        & svg {
          stroke: $grayBD;
          cursor: pointer;
        }
        p {
          cursor: pointer;
        }
      }

      & .locationBlock {
        flex: 1 1 auto;

        display: flex;
        align-items: center;
        @include text_x_small_extrabold;
        color: $grayBD;
        gap: 3px;

        & svg {
          stroke: $grayBD;
          cursor: pointer;
        }
        p {
          cursor: pointer;
        }
      }

      & .sizeBlock {
        flex: 1 1 auto;
        display: flex;
        align-items: center;
        @include text_x_small_extrabold;
        color: $grayBD;
        gap: 3px;

        & svg {
          stroke: $grayBD;
          cursor: pointer;
        }
        p {
          cursor: pointer;
        }
      }
    }
    & .reportsContainer {
      & .loaderWrapper {
        display: flex;
        align-items: center;
        justify-content: center;
        height: 50vh;
      }
    }
    & .paginationContainer {
      margin-top: 30px;
      display: flex;
      gap: 8px;

      & .nextBtn {
        cursor: pointer;
        background-color: $primary;
        padding: 14px 30px;
        border-radius: 16px;
        font-weight: 700;
        font-size: 14px;
        line-height: 20px;
        color: $white;
        font-family: $secondary-font;
      }
    }
  }
}
.activeBlockAZ {
  color: $black15 !important;
  svg {
    fill: $black15 !important;
  }
}
.activeBlockZA {
  color: $black15 !important;
  transition: 0.3s;
  svg {
    fill: $black15 !important;
    rotate: (-180deg) !important;
  }
}
.activeBlock {
  color: $black15 !important;
  transition: 0.3s;
  svg {
    rotate: (-180deg) !important;
    fill: $black15 !important;
  }
}
