@import "../../../scss/main.scss";
.input {
  width: 450px;
}
.navBody {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 28px;
  button {
    width: 160px;
    border-radius: 16px;
  }
}

.menuBody {
  display: flex;
  gap: 2px;
  margin-bottom: 25px;
}

.wrapper {
  max-width: 1520px;
  & .header {
    display: flex;
    justify-content: space-between;
    align-items: center;

    & .title {
      @include text_statistic_bold;
    }
  }
  & .contentContainer {
    & .searchContainer {
      display: flex;
      align-items: center;
      margin: 28px 0;
    }

    & .sectionHeader {
      position: relative;
      display: grid;
      overflow-wrap: normal;
      align-items: center;
      padding-bottom: 7px;
      border-bottom: 1px solid $shadow;

      & > div {
        display: flex;
        align-items: center;
        @include text_x_small_extrabold;
        transition: 0.3s;
        color: $grayBD;
        gap: 3px;

        & svg {
          stroke: $grayBD;
          cursor: pointer;
        }
        p {
          cursor: pointer;
        }
      }
    }
    & .sectionHeaderEvents {
      grid-template-columns:
        minmax(220px, 1fr) minmax(50px, 110px) minmax(120px, 400px)
        minmax(80px, 110px) minmax(100px, 200px) 30px;
    }
    & .sectionHeaderRequests {
      grid-template-columns:
        minmax(250px, 1fr) minmax(130px, 140px) minmax(120px, 400px)
        minmax(110px, 150px) minmax(100px, 200px) 77px;
    }
    & .meetupsContainer {
      & .loaderWrapper {
        display: flex;
        align-items: center;
        justify-content: center;
        height: 50vh;
      }
    }
    & .paginationContainer {
      margin-top: 30px;
      display: flex;
      gap: 8px;

      & .nextBtn {
        cursor: pointer;
        background-color: $primary;
        padding: 14px 30px;
        border-radius: 16px;
        font-weight: 700;
        font-size: 14px;
        line-height: 20px;
        color: $white;
        font-family: $secondary-font;
      }
    }
  }
}
