@import "../../scss/main.scss";

.popap {
  position: absolute;
  z-index: 20;
  top: 20px;
  right: 0;
  background-color: $white;
  .edit {
    margin: 0;
    font-family: $primary-font;
    font-size: 14px;
    color: $black15 !important;
    padding: 5px 10px;
    border-radius: 10px;
    text-align: center;
    cursor: pointer;
    &:hover {
      background: rgb(235, 235, 235, 0.6);
    }
  }
  .delete {
    margin: 0;
    font-family: $primary-font;
    font-size: 14px;
    color: $red !important;
    padding: 5px 10px;
    border-radius: 10px;
    cursor: pointer;
    &:hover {
      background: rgb(235, 235, 235, 0.6);
    }
  }
}
