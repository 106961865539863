@import "../../scss/main.scss";
.postWrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid $grayE4;
  min-height: 90px;
}
.detailBody {
  display: flex;
  align-items: center;
  gap: 40px;
}
.date {
  @include text_small_bold;
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
  margin: 0 0 5px 0;

  color: $gray91;
}
.infoBody {
  span {
    max-width: 537px;
    @include text_small_bold;
    font-weight: 400;
    font-size: 14px;
    line-height: 24px;

    color: $black1E;
  }
}
.image {
  min-width: 90px;
  width: 90px;
  height: 80px;
  border-radius: 10px;
  overflow: hidden;
  margin: 10px 0;
  img,
  video {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
}
// .basicImage {
//   width: 90px;
//   height: 50px;
//   margin: 20px 0;
// }
.likesBody {
  display: flex;
  align-items: center;
  gap: 6px;
  span {
    @include text_small_bold;
    font-weight: 700;
    line-height: 20px;
    color: $gray91;
  }
}
.commentsBody {
  display: flex;
  align-items: center;
  gap: 6px;
  span {
    @include text_small_bold;
    font-weight: 700;
    line-height: 20px;
    color: $gray91;
  }
}
.trashBody {
  cursor: pointer;
}
