@import "./../../scss/main.scss";
.emptyState {
  width: 750px;
  margin: 40px auto;
  border-radius: 4px;

  .emptyStateContent {
    padding: 48px;
    display: flex;
    align-items: center;
    flex-direction: column;

    .emptyStateMessage {
      font-family: $secondary-font;
      color: $primary;
      font-size: 1.5rem;
      font-weight: 500;
      margin-top: 0.85rem;
      margin-bottom: 15px;
    }

    .emptyStateHelp {
      svg {
        fill: $grayBD !important;
        width: 60px !important;
        height: auto !important;
      }
    }
  }
}
