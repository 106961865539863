@import "../../scss/main.scss";

.input {
  position: relative;
  &__main {
    width: 100%;
    flex: 1;
    background-color: rgba(228, 228, 228, 0.25);
    border-radius: 8px;
    padding: 16px 21px;

    @include text_input_bold;
    caret-color: $primary;
    color: $black;

    &::placeholder {
      color: $black15;
    }
    border: 2px solid transparent;
    &.error {
      border-color: $red;
    }

    &.borderError {
      border-color: $red;
    }

    &:focus {
      outline: none;
      border-color: $primary;
      border-width: 2px;
    }
    &:disabled {
      background: rgba(228, 228, 228, 0.49776) !important;
      color: $grayBD !important;
      @include text_form_label;
    }
  }
  span {
    display: block;
    margin-bottom: 16px;
    @include text_form_label;
    color: $grayBD;
  }
  p {
    font-size: 11px;
    color: $red;
    line-height: 18px;
  }
}

.inputWrapper {
  display: flex;
  align-items: center;

  position: relative;
  input {
    &::placeholder {
      @include text_form_label;
    }
  }
}
.errorText {
  font-size: 11px;
  color: $red;
  line-height: 18px;
  margin-top: 3px;
}
