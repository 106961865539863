@import "../../scss/main.scss";

.overlay {
  position: fixed;
  display: flex;
  justify-content: center;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.58);
  z-index: 1000;
  overflow-y: auto;
}

.modal {
  & .innerContainer {
    position: relative;
    width: 835px;
    margin-top: 100px;
    padding: 32px;
    border-radius: 24px;
    background: $white;
  }
  .header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 22px;
    h5 {
      margin: 0;
      font-family: $primary-font;
      font-size: 18px;
      line-height: 24px;
      font-weight: 700;
      font-size: 18px;
      line-height: 24px;
    }
    svg {
      cursor: pointer;
    }
  }
}
.commonError {
  font-size: 11px;
  color: $red;
  line-height: 18px;
  margin: 10px 0 0 0;
  margin-bottom: 10px;
}
.input {
  flex: 1;

  span {
    @include text_form_label;
    color: $grayBD;
  }

  textarea {
    @include text_input_bold;
    caret-color: $primary;
    color: $black;
  }
}

.meetup {
  &__form {
    display: flex;
    flex-direction: column;
    gap: 22px;

    button {
      border-radius: 16px;
    }

    &--row {
      display: flex;
      gap: 10px;

      &-date-picker {
        flex: 1;
      }

      &-time {
        width: 145px;
      }
    }

    &--columns {
      display: flex;
      gap: 30px;
    }
    &--leftColumn {
      flex: 1;
      display: flex;
      flex-direction: column;
      gap: 20px;
    }
    &--rightColumn {
      flex: 1;
      max-width: 310px;
      display: flex;
      flex-direction: column;
      gap: 20px;
    }
    &--participants {
      display: flex;
      justify-content: space-between;
      column-gap: 10px;
    }
    &--image {
      display: flex;
      flex-direction: column;
      align-items: center;
      gap: 10px;

      &_title {
        @include text_form_label;
        color: $gray91;
        align-self: flex-start;
      }
      &_btn {
        cursor: pointer;
      }
      &_btnBig {
        cursor: pointer;
        width: 100%;
      }
      &_eventAvatar {
        // min-width: 100px;
        width: 100%;
        height: auto;
        aspect-ratio: 311/132;
        object-fit: cover;
        border-radius: 8px;
        overflow: hidden;
        background-color: $shadow;
      }
      &_orgAvatar {
        min-width: 100px;
        width: 100px;
        height: 100px;
        object-fit: cover;
        border-radius: 50%;
        overflow: hidden;
      }
      &_info {
        display: flex;
        gap: 15px;
        span {
          letter-spacing: 0.3px;
          cursor: pointer;
        }
      }
      &_change {
        @include text_form_label;
        color: $deactive;
        &:hover {
          color: $black15;
        }
      }
      &_delete {
        @include text_form_label;
        color: $red;
        &:hover {
          color: rgb(204, 0, 0);
        }
      }
    }
  }
}
