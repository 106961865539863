@import "../../scss/main.scss";

.overlay {
  // display: flex;
  // justify-content: center;
  // position: absolute;
  // // height: 80vw;
  // overflow: auto;
  // top: 0;
  // bottom: 0;
  // left: 0;
  // right: 0;
  // background: rgba(0, 0, 0, 0.58);
  position: fixed;
  display: flex;
  justify-content: center;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.58);
  z-index: 1000;
  overflow-y: auto;
}

.modal {
  & .innerContainer {
    position: relative;
    width: 460px;
    margin-top: 100px;
    padding: 32px;
    border-radius: 24px;
    background: $white;
  }
  .header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 22px;
    h5 {
      margin: 0;
      font-family: $primary-font;
      font-size: 18px;
      line-height: 24px;
      font-weight: 700;
      font-size: 18px;
      line-height: 24px;
    }
    svg {
      cursor: pointer;
    }
  }
}
.profileSection {
  span {
    display: block;
    @include text_form_label;
    margin-bottom: 10px;
  }
  a {
    margin: 0;
    text-decoration: none;
    color: $primary;
    font-family: $primary-font;
    font-weight: 700;
    font-size: 14px;
    line-height: 20px;
  }
}
.profileBody {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.avatarBody {
  .avatar {
    min-width: 100px;
    width: 100px;
    height: 100px;
    object-fit: cover;
    border-radius: 50%;
    overflow: hidden;
  }
  .avatarBtn {
    cursor: pointer;
  }
}
.avatarInfoBody {
  display: flex;
  gap: 15px;
  margin-top: 10px;
  span {
    @include text_form_label;
    font-size: 12px;
    line-height: 16px;
    letter-spacing: 0.3px;
    cursor: pointer;
    &:hover {
      color: $black15;
    }
  }
}
.deleteBody {
  color: $red !important;
  &:hover {
    color: rgb(204, 0, 0) !important;
  }
}
.dividerX {
  height: 1px;
  background: $grayE4;
  width: 100%;
  margin: 20px 0;
}
.names {
  display: flex;
  justify-content: space-between;
  column-gap: 10px;
}
.email {
}
.password {
}
.role {
}
.form {
  display: flex;
  flex-direction: column;
  row-gap: 22px;

  button {
    border-radius: 16px;
  }
}
.commonError {
  font-size: 11px;
  color: $red;
  line-height: 18px;
  margin: 10px 0 0 0;
  margin-bottom: 10px;
}
.input {
  flex: 1;
}
