@import "../../scss/main.scss";

.modal {
  &__overlay {
    position: fixed;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.58);
    display: flex;
    align-items: center;
    justify-content: center;
  }

  width: 460px;
  padding: 32px;
  background-color: $white;
  border-radius: 24px;
  outline: none;

  &__header {
    position: relative;
    margin-bottom: 22px;

    &--title {
      font-weight: 700;
      font-size: 18px;
      color: $black2D;
      line-height: 24px;
    }

    &--subtitle {
      font-weight: 400;
      font-size: 14px;
      color: $gray91;
      line-height: 24px;
      margin-top: 10px;
    }

    &--close {
      position: absolute;
      right: 0;
      top: 0;
      cursor: pointer;
    }
  }
}
