@import "../../scss/main.scss";

.businessBody {
  position: relative;
  & .select {
    position: absolute;
    top: 38%;
    right: 0;

    cursor: pointer;
    transition: 0.3s;
    &:hover svg {
      scale: 1.2;
    }

    > div {
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
}
.wrapper {
  border-bottom: 1px solid $shadow;
  padding: 10px 0;
  display: flex;
  align-items: center;
  transition: all 0.2s linear;
  cursor: pointer;
  position: relative;
  &:hover {
    background: rgb(250, 250, 250);
  }
  display: grid;
  grid-template-columns:
    minmax(140px, 324px) minmax(50px, 312px) minmax(50px, 336px)
    30px;

  & .email {
    & p {
      overflow-wrap: break-word;

      @include text_staff_regular;
      font-size: 14px;
      line-height: 24px;
      color: $black15;
    }
  }
  & .role {
    & p {
      overflow-wrap: break-word;

      @include text_staff_bold;
      color: $black15;
    }
  }
}
