@import "../../scss/main.scss";
.selectHeader {
  padding: 14px 21px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: rgba(228, 228, 228, 0.35);
  border-radius: 8px;
  transition: 0.3s;
  border: solid 2px transparent;
  span {
    @include text_small_bold;
  }
}
.selectHeaderOpen {
  border-radius: 8px 8px 0 0;
  border: solid 2px $primary;
}
.selectHeaderMulti {
  padding: 13px 23px;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  background: rgba(228, 228, 228, 0.35);
  border-radius: 8px;
  transition: 0.3s;
  height: 20px;
  overflow: hidden;
  border: solid 2px transparent;
  span {
    @include text_small_bold;
    line-height: 26px;
  }
}
.selectHeaderMultiOpen {
  padding: 13px 23px;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  transition: 0.3s;
  height: 20px;
  overflow: hidden;
  background: rgba(228, 228, 228, 0.35);
  border-radius: 8px 8px 0 0;
  border: solid 2px $primary;
  span {
    @include text_small_bold;
    line-height: 26px;
  }
}
// &:focus {
//   outline: none;
//   border-color: $primary;
//   border-width: 2px;
// }
.wrapper {
  display: flex;
  flex-direction: column;
}
.multiWrapper {
  margin-top: 25px;
}
.selectBody {
  position: relative;
  cursor: pointer;
}
.label {
  @include text_form_label;
  color: $grayBD;
  margin-bottom: 10px;
}
.subtitle {
  @include text_staff_subtitle;
  margin-bottom: 10px;
}

.option {
  height: 0;
  visibility: hidden;
  overflow: hidden;
  border-width: 2px;
  // border-style: solid;
  border-color: $primary;
}
.optionOpen {
  position: absolute;
  margin: -2px 0 0 0;
  width: 100%;
  visibility: visible;
  // transition: 0.3s;
  height: auto;
  z-index: 10;
  border-width: 2px;
  border-style: solid;
  border-color: $primary;
  box-sizing: border-box;
  // padding: 18px 23px;
  background: rgb(250, 250, 250);
  max-height: 150px;
  overflow-y: auto;
}
.lastOption {
  visibility: visible;
  height: auto;
  // padding: 5px 23px;
  background: rgb(250, 250, 250);
  border-radius: 0 0 8px 8px;
}
.optionItem {
  span {
    @include text_small_bold;
    margin: 10px 20px;
  }
}
.arrow {
  transition: 0.3s;
  transform: rotate(0deg);
}
.arrowOpen {
  transition: 0.3s;
  transform: rotate(180deg);
}
.fullInformation {
  display: flex;
  align-items: center;
  gap: 10px;
  @include text_staff_bold;
  font-size: 14px;
  line-height: 20px;
  img {
    min-width: 32px;
    width: 32px;
    height: 32px;
    border-radius: 50%;
  }
}
.fullInformationOption {
  display: flex;
  align-items: center;
  gap: 10px;
  @include text_staff_bold;
  font-size: 14px;
  line-height: 20px;
  padding: 5px 23px;
  img {
    min-width: 32px;
    width: 32px;
    height: 32px;
    border-radius: 50%;
  }
  &:hover {
    margin: 0;
  }
  span {
    margin: 0;
  }
}
.selected {
  color: $primary;
}
.needWrapper {
  display: flex;
  gap: 10px;
  flex-wrap: wrap;
}
.disabled {
  cursor: default !important;
  color: $grayBD !important;
}
.needBody {
  padding: 11px 16px;
  border: 2px solid #eeeeee;
  border-radius: 8px;
  @include text_statistic_bold;
  font-size: 14px;
  line-height: 20px;
  white-space: nowrap;
  cursor: pointer;
}
.needBodySelected {
  padding: 11px 16px;
  border: 2px solid $primary;
  border-radius: 8px;
  @include text_statistic_bold;
  color: $primary;
  background: #f1f5ff;
  font-size: 14px;
  line-height: 20px;
  white-space: nowrap;
  cursor: pointer;
}
.needBodyOther {
  padding: 11px 16px;
  border: 2px solid $primary;
  border-radius: 8px;
  @include text_statistic_bold;
  color: $primary;
  background: #f1f5ff;
  font-size: 14px;
  line-height: 20px;
  white-space: nowrap;
  cursor: pointer;
}
.error {
  border-color: $red;
}
