.create-meeting {
  width: 550px;

  &__form {
    display: flex;
    flex-direction: column;
    gap: 22px;

    &--row {
      display: flex;
      gap: 10px;

      &-date-picker {
        flex: 1;
      }

      &-time {
        width: 145px;
      }
    }
  }
}
