@import "vars";

//DESKTOP-HEADING
@mixin h1 {
  font-family: $primary-font;
  font-style: normal;
  font-weight: 700;
  font-size: 32px;
  line-height: 40px;
  letter-spacing: -0.5px;
  color: $black15;
}

@mixin h2 {
  font-family: $primary-font;
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  line-height: 32px;
  color: $black15;
}
@mixin h3 {
  font-family: $primary-font;
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  line-height: 24px;
  color: $black15;
}

//TEXT
@mixin text-menu {
  font-family: $primary-font;
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 20px;
  color: $gray91;
}
@mixin text-menu_selected {
  font-family: $primary-font;
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 20px;
  color: $white;
}
@mixin tag-activate {
  font-family: $primary-font;
  font-style: normal;
  font-weight: 700;
  font-size: 13px;
  line-height: 18px;
  color: $white;
}
@mixin tag-primary {
  font-family: $secondary-font;
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 16px;
  color: $primary;
}

@mixin text_statistic_regular {
  font-family: $primary-font;
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 32px;
  color: $black15;
}
@mixin text_statistic_bold {
  font-family: $primary-font;
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  line-height: 32px;
  color: $black15;
}

@mixin text_staff_regular {
  font-family: $primary-font;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
  color: $black15;
}
@mixin text_staff_bold {
  font-family: $primary-font;
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 20px;
  color: $black15;
}
@mixin text_staff_subtitle {
  font-family: $primary-font;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
  color: $gray91;
}
@mixin text_small_bold {
  font-family: $primary-font;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 22px;
}
@mixin text_form_label {
  font-family: $secondary-font;
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 16px;
  color: $gray91;
}
@mixin text_x_small_bold {
  font-family: $primary-font;
  font-style: normal;
  font-weight: 600;
  font-size: 13px;
  line-height: 18px;
}

@mixin text_x_small_medium {
  font-family: $primary-font;
  font-style: normal;
  font-weight: 500;
  font-size: 13px;
  line-height: 18px;
}

@mixin text_x_small_extrabold {
  font-family: $primary-font;
  font-style: normal;
  font-weight: 800;
  font-size: 13px;
  line-height: 18px;
}
@mixin text_x_small_regular {
  font-family: $primary-font;
  font-style: normal;
  font-weight: 400;
  font-size: 13px;
  line-height: 18px;
}
@mixin text_medium_bold {
  font-family: $primary-font;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 26px;
}

@mixin text_input_bold {
  font-family: $primary-font;
  font-style: normal;
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
}
