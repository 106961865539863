//fonts
$primary-font: "EudoxusSans", "sans-serif";
$secondary-font: "Inter", "sans-serif";

//colors
$white: #ffffff;
$black: #000000;
$black2D: #11142d;
$black15: #11142d;
$black1E: #1b1c1e;
$textMain: #54585f;
$primary: #e13f4e;
$additional: #f9d9dc;
$grayE4: #e4e4e4;
$gray60: #535860;
$grayBD: #b2b3bd;
$gray91: #808191;
$shadow: #e3e6ec;
$deactive: #b2b3bd;
$red: #ff3c3c;
$orange: #ff754c;
$green: #63cd5a;
$green7A: #7fba7a;
