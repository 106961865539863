@import "../../scss/main.scss";

.wrapper {
  display: flex;
  position: relative;
  background-color: #e1e4e7;
  //   border-radius: 100px;
  width: 100%;
  height: 100%;

  .loader {
    position: absolute;
    width: 100%;
    height: 100%;
    background-color: #e1e4e7;
    // border-radius: 100px;
    z-index: 1;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}
