@import "../../scss/main.scss";

.table {
  &__head {
    display: flex;
    align-items: center;
    border-bottom: 1px solid $shadow;

    &--col {
      flex: 1;
      display: flex;
      align-items: center;
      padding: 0 4px 14px;
      @include text_x_small_extrabold;
      color: $grayBD;

      &-sorting {
        cursor: pointer;
        user-select: none;
      }

      &-desc,
      &-asc {
        color: $black15;
      }

      &-asc &-arrow {
        & > path {
          fill: $black15;
        }
      }

      &-desc &-arrow {
        transform: rotate(-180deg);

        & > path {
          fill: $black15;
        }
      }

      &-arrow {
        width: 14px;
        height: 14px;
        margin-left: 3px;
        transition: 0.2s;

        & > path {
          fill: $grayBD;
        }
      }
    }
  }

  &__body {
    &--loader {
      display: flex;
      align-items: center;
      justify-content: center;
      height: 100px;
    }

    &--row {
      height: 64px;
      display: flex;
      border-bottom: 1px solid $shadow;
    }

    &--col {
      flex: 1;
      display: flex;
      align-items: center;
      height: 100%;
      padding: 0 4px;
      font-family: $secondary-font;
      font-size: 14px;
      color: $black2D;
      line-height: 20px;
    }
  }
}
