@import "../../scss/main.scss";

.complaint {
  @include text_x_small_bold;
  font-weight: 700;
  color: $white;
  display: inline;
  padding: 3px 10px;
  background: $orange;
  border-radius: 8px;
}
.disabled {
  @include text_x_small_bold;
  font-weight: 700;
  color: $white;
  display: inline;
  border-radius: 8px;
  padding: 3px 10px;
  background: $grayBD;
}
