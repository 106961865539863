@import "../../scss/main.scss";

.statsBody {
  display: flex;
  align-items: center;
  gap: 5px;
  p {
    font-family: $secondary-font;
    font-weight: 500;
    font-size: 12px;
    line-height: 16px;
    margin: 0;
  }
}
.arrowBody {
  background: $green;
  width: 16px;
  height: 16px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.arrowBodyDescrease {
  background: $red;
  width: 16px;
  height: 16px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.arrowUp {
  fill: white;
}
.decreaseInfo {
  color: $red;
}
.increaseInfo {
  color: $green;
}
