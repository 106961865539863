.group-meeting {
  position: relative;

  &__button {
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
  }
}
