@import "../../scss/main.scss";

.filterHeader {
  display: flex;
  align-items: center;
  gap: 80px;
  margin-bottom: 20px;
  p {
    @include text_small_bold;
    font-size: 18px;
    line-height: 24px;
    flex: 1 0 auto;
    margin: 0;
  }
  span {
    cursor: pointer;
    @include text_small_bold;
    color: $primary;
    font-family: $secondary-font;
    line-height: 20px;
    width: 100px;
    margin-right: 20px;
    flex: 1 0 auto;
    transition: 0.3s;
    &:hover {
      color: rgb(119, 7, 7);
    }
  }
}
.radioBody {
  p {
    @include text_form_label;
    font-family: $secondary-font;
    font-style: normal;
    font-weight: 700;
    font-size: 14px;
    line-height: 20px;
    margin: 0 0 16px 0;
  }
}
.checkboxBody {
  display: flex;
  flex-direction: column;
  gap: 15px;
}
.dividerX {
  height: 1px;
  width: 100%;
  background: $grayE4;
  margin: 20px 0;
}
.locationBody {
  width: 92%;
  span {
    font-family: $secondary-font;
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 16px;
  }
}
.applyButton {
  margin-top: 20px;
}
.checkboxContainer {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 8px;
  span {
    @include text_staff_bold;
    font-size: 14px;
  }
}
