@import "../../../scss/main.scss";

.wrapper {
  display: flex;
  flex-direction: column;
  gap: 25px;

  & .loaderWrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 70vh;
  }

  .info {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 20px;

    & .nameWrapper {
      & .name {
        @include h1;
        margin-bottom: 7px;
      }

      & .category {
        font-family: $secondary-font;
        font-size: 14px;
        line-height: 20px;
        color: $gray60;
        font-weight: 400;
      }
    }

    & .instructorWrapper {
      display: flex;
      align-items: center;
      gap: 10px;

      & .instructorAvatar {
        min-width: 32px;
        width: 32px;
        height: 32px;
        border-radius: 100px;
        object-fit: cover;
        overflow: hidden;
      }
      & .instructorNameWrapper {
        & .instructorName {
          @include text-menu;
          color: $black2D;
        }
        & .instructorTitle {
          font-family: $primary-font;
          font-style: normal;
          font-weight: 400;
          font-size: 13px;
          line-height: 24px;
          color: $gray60;
        }
      }
    }
  }

  & .about {
    @include text_statistic_regular;
  }

  & .tabMenu {
    display: flex;
    align-items: center;
    justify-content: space-between;
    min-height: 56px;
  }

  & .addNewBtn {
    width: auto;
    padding: 0 25px;
  }

  & .tabWrapper {
    display: flex;
    gap: 2px;
  }
}

.btnWrapper {
  display: flex;
  gap: 10px;
}

.groupsWrapper {
  & .sectionHeader {
    // display: flex;
    position: relative;
    display: grid;
    overflow-wrap: normal;
    grid-template-columns:
      minmax(200px, 1fr) 180px minmax(50px, 180px)
      minmax(100px, 180px) 30px;
    align-items: center;
    padding-bottom: 7px;
    border-bottom: 1px solid $shadow;

    & > div {
      display: flex;
      align-items: center;
      @include text_x_small_extrabold;
      transition: 0.3s;
      color: $grayBD;
      gap: 3px;

      & svg {
        stroke: $grayBD;
        cursor: pointer;
      }
      p {
        cursor: pointer;
      }
    }
  }
  & .groupsContainer {
    & .loaderWrapper {
      display: flex;
      align-items: center;
      justify-content: center;
      height: 50vh;
    }
  }
}
