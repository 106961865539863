@import "../../scss/main.scss";

.input {
  position: relative;
  &__mainGoogle {
    width: 100%;
    flex: 1;
    background-color: rgba(228, 228, 228, 0.25);
    border-radius: 8px;
    font-size: 14px;
    font-weight: 500;
    padding: 18px 23px;
    line-height: 22px;
    caret-color: $primary;
    color: $black;
    @include text_small_bold;
    &:disabled {
      cursor: default;
      color: $grayBD;
    }
    &::placeholder {
      color: $black15;
    }
    border: 2px solid transparent;
    &.error {
      border-color: $red;
    }

    &.borderError {
      border-color: $red;
    }

    &:focus {
      outline: none;
      border-color: $primary;
      border-width: 2px;
      border-radius: 8px 8px 0 0;
    }
  }
  &__main {
    width: 100%;
    flex: 1;
    background-color: rgba(228, 228, 228, 0.25);
    border-radius: 8px;
    padding: 16px 21px;

    @include text_input_bold;
    caret-color: $primary;
    color: $black;

    &::placeholder {
      color: $black15;
    }
    border: 2px solid transparent;
    &.error {
      border-color: $red;
    }

    &.borderError {
      border-color: $red;
    }

    &:focus {
      outline: none;
      border-color: $primary;
      border-width: 2px;
    }
    &:disabled {
      background: rgba(228, 228, 228, 0.49776) !important;
      color: $grayBD !important;
      @include text_form_label;
    }
  }
  span {
    display: block;
    margin-bottom: 16px;
    @include text_form_label;
    color: $grayBD;
  }
  p {
    font-size: 11px;
    color: $red;
    line-height: 18px;
  }
  .show {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    right: 25px;
    margin-bottom: 0 !important;
    @include text_form_label;
    color: $grayBD;
    letter-spacing: 1.2px;
    cursor: pointer;
    &:hover {
      color: $black15;
    }
  }
}
.withIcon {
  padding-left: 50px;
  padding: 15px 23px 15px 50px;
  &::placeholder {
    padding-left: 0px;
    @include text_form_label;
    font-size: 14px;
  }
}

.inputWrapper {
  display: flex;
  align-items: center;

  position: relative;
  input {
    &::placeholder {
      @include text_form_label;
    }
  }
  .cancelRemoveInput {
    position: absolute;
    top: 18px;
    right: 10px;
    cursor: pointer;
  }

  // .pac-item {
  //   background: green !important;
  //   &::after {
  //     display: none !important;
  //   }
  // }
}
.icon {
  position: absolute;
  top: 15px;
  left: 15px;
}
.errorText {
  font-size: 11px;
  color: $red;
  line-height: 18px;
  margin-top: 3px;
}
