@import "../../../../scss/main.scss";
.accountBody {
  display: flex;
  gap: 50px;
}
.interestBody {
  display: flex;
  align-items: flex-end;
  gap: 5px;
  margin-bottom: 5px;
  flex-wrap: wrap;
}
.nameTag {
  @include text_statistic_regular;
  margin: 25px 0 10px 0;
  font-family: $secondary-font;
  font-weight: 600;
  font-size: 16px;
  line-height: 22px;
}
.accountInfoTitle {
  @include text_statistic_regular;
  font-weight: 700;
  font-size: 18px;
  line-height: 24px;
  color: $black1E;
  margin: 0 0 10px 0;
}
.accountInfoBody {
  // width: 406px;
  width: 100%;
}
.accountInfoTitleLooking {
  @include text_statistic_regular;
  font-weight: 700;
  font-size: 18px;
  line-height: 24px;
  color: $black1E;
  margin: 25px 0 10px 0;
}
.accountInfoSubtitle {
  @include text_statistic_regular;
  font-weight: 400;
  font-size: 18px;
  color: $black1E;
  margin: 0;
}

.accountTags {
  flex: 0 0 55%;
}
.accountInfo {
  flex: 1 1 auto;
}
.photosEmpty {
  @include text_medium_bold;
}
