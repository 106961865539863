@import "../../scss/main.scss";
.title {
  @include h2;
  padding: 15px 0 15px 20px;
  display: flex;
  align-items: center;
  gap: 10px;
  div {
    width: 40px;
    height: 40px;
    img {
      width: 100%;
    }
  }
}
.tag {
  font-weight: 600;
}
.wrapper {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  flex: 0 0 256px;
  max-height: 100vh;
  min-height: 100vh;
  position: sticky;
  top: 0;
  overflow-y: scroll;

  &::-webkit-scrollbar {
    width: 5px;
  }

  &::-webkit-scrollbar-track {
    background: none;
  }

  &::-webkit-scrollbar-thumb {
    border-radius: 100px;
    background: $grayE4;
  }

  &::-webkit-scrollbar-thumb:hover {
    background: $grayE4;
  }

  .navOutside {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100%;
  }
}
.navWrapper {
  margin: 0 20px 0 17px;
}
.logo {
  margin: 26px 0 40px 10px;
}
.logoutWrapper {
  position: absolute;
  visibility: hidden;
  width: 100%;
  top: 0px;
  left: 0;
  padding: 0 20px;
  z-index: 1;
}
.navButtonSelected {
  padding: 17px 22px;
  background: $primary;
  display: flex;
  align-items: center;
  gap: 15px;
  border-radius: 12px;
  outline: none;
  border: solid 2px transparent;
  font-family: $primary-font;
  width: 100%;
  cursor: pointer;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  margin-bottom: 1px;
  text-decoration: none;
  span {
    @include text-menu_selected;
    white-space: nowrap;
  }
  &:hover {
    opacity: 0.9;
  }
  svg {
    fill: white;
  }
}
.navButton {
  padding: 17px 22px;
  display: flex;
  align-items: center;
  gap: 15px;
  border-radius: 12px;
  outline: none;
  border: solid 2px transparent;
  font-family: $primary-font;
  width: 100%;
  cursor: pointer;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  text-decoration: none;
  transition: 0.3s;
  margin-bottom: 1px;

  span {
    @include text-menu;
    white-space: nowrap;
  }
  &:hover {
    background: $grayE4;
  }
  svg {
    fill: #b2b3bd;
  }
}
.subNav {
  @include text_staff_bold;
  display: flex;
  align-items: center;
  gap: 10px;
  padding: 5px;
  cursor: pointer;
  transition: 0.3s;
  letter-spacing: 0.2px;
  &:hover {
    background: rgba(232, 232, 232, 0.4);
    border-radius: 10px;
  }
}
.svg {
  width: 20px;
  fill: $primary;
}
.profileWrapper {
  position: relative;
  padding: 25px 40px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
  transition: 0.3s;
  // max-width: 190px;
  span {
    overflow-wrap: break-word;
    max-width: 140px;
  }
  p {
    overflow-wrap: break-word;
    max-width: 120px;
  }
  &:hover {
    background: rgba(241, 241, 241, 0.745);
  }
}
.image {
  min-width: 40px;
  width: 40px;
  height: 40px;
  flex: 0 0 auto;
  border-radius: 50%;
  overflow: hidden;
  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
}
.profileBody {
  display: flex;
  flex-direction: column;
  p {
    @include text_form_label;
    margin: 0;
  }
  .organizatiorManager {
    font-size: 11px;
  }
  .isLargeName {
    font-size: 11px;
  }
}
.profileTitle {
  @include text_staff_bold;
  font-size: 14px;
  line-height: 20px;

  color: #11142d;
}
.arrowWrapper {
  display: flex;
  flex-direction: column;
  cursor: pointer;
  &:hover {
    fill: $gray91;
  }
}
